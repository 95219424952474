import React from "react"

import { Link } from "gatsby"

const Footer = ({ data, location }) => {
  return (
    <section className="background-footer">
      <section className="footer">
        <section className="section-footer">
          <section className="text-footer">
            <p className="title-footer">{data.product}</p>
          
            <p className="title-product-footer-desktop">
              <Link to={`${data.linktwo}${location.search}`}>
                {data.productDesktopTwo}
              </Link>
            </p>
            <p className="title-product-footer-mobile">
              <Link to={`${data.linktwo}${location.search}`}>
                {data.productMobileTwo}
              </Link>
            </p>
            <p className="title-product-footer-desktop">
              <Link to={`${data.linkone}${location.search}`}>
                {data.productDesktopOne}
              </Link>
            </p>
            <p className="title-product-footer-mobile">
              <Link to={`${data.linkone}${location.search}`}>
                {data.productMobileOne}
              </Link>
            </p>
            <p className="title-product-footer-desktop">
              <Link to={`${data.linkfour}${location.search}`}>
                {data.productDesktopFour}
              </Link>
            </p>
            <p className="title-product-footer-mobile">
              <Link to={`${data.linkfour}${location.search}`}>
                {data.productMobileFour}
              </Link>
            </p>
          </section>
          <section className="text-footer">
            <p className="title-footer">CANALES</p>
            <p>
              <Link to={`${data.linkwhatsapp}${location.search}`}>
                {data.solutionFive}
              </Link>
            </p>
            <p>
              <Link to={`/software-voip/${location.search}`}>Voz</Link>
            </p>
            <p>
              <Link to={`/email/${location.search}`}>Correo electrónico</Link>
            </p>
            <p>
              <Link to={`/sms/${location.search}`}>SMS</Link>
            </p>
           
            <p>
              <Link to={`${data.linkinstagram}${location.search}`}>
                {data.solutionSeven}
              </Link>
            </p>
            <p>
              <Link to={`${data.linkwebchat}${location.search}`}>
                {data.solutionEight}
              </Link>
            </p>
            <p>
              <Link to={`/conversations/${location.search}`}>Messenger</Link>
            </p>
          </section>
          <section className="text-footer">
            <p className="title-footer">{data.solution}</p>
            <p>
              {" "}
              <Link to={`${data.linkfive}${location.search}`}>
                {data.solutionOne}
              </Link>
            </p>
            <p>
              <Link to={`${data.linksix}${location.search}`}>
              Marketing y ventas
              </Link>
            </p>
            <p>
              <Link to={`${data.linkeight}${location.search}`}>
               Cobros y Pagos
              </Link>
            </p>
          </section>
          <section className="text-footer">
            <p className="title-footer">RECURSOS</p>
            <p>
              {" "}
              <Link to={`https://beexcc.com/blog/`}>Blog</Link>
            </p>
            <p>
              <Link to={`/recursos/${location.search}`}>Descargables</Link>
            </p>
            <p>
              <Link to={`/videos/${location.search}`}>Videos</Link>
            </p>
            <p>
              <Link to={`/videoblog/${location.search}`}>Videoblogs</Link>
            </p>
            <p>
              <Link to={`/podcast/${location.search}`}>Podcast</Link>
            </p>
          </section>
          <section className="text-footer">
            <p className="title-footer">{data.company}</p>
            <p>
              {" "}
              <Link to={`${data.linknine}${location.search}`}>
                {data.companyOne}
              </Link>
            </p>
            <p>
              {" "}
              <Link to={`${data.linkten}${location.search}`}>
                {data.companyTwo}
              </Link>
            </p>
            <p>
              {" "}
              <Link href={`/jobs-beex/${location.search}`}>
                Trabaja con nosotros
              </Link>
            </p>
            <p>
              {" "}
              <Link href={`/soporte-con-amor/${location.search}`}>
                Experiencia de Soporte
              </Link>
            </p>
            <p>
              {" "}
              <Link href={`/programa-partners-beex/${location.search}`}>
                Partners
              </Link>
            </p>
            <p>
              {" "}
              <Link href={`${data.linkdocs}${location.search}`}>
                {data.companySix}
              </Link>
            </p>

            <p>
              {" "}
              <Link href={`${data.linktwelve}${location.search}`}>
                {data.companyFour}
              </Link>
            </p>
          </section>
          <section className="text-footer">
            <p className="title-footer">{data.blog}</p>
            <p>
              <Link href="https://beexcc.com/blog/noticias-atencion-al-cliente/">
                Atención al cliente
              </Link>
            </p>
            <p>
              <Link href="https://beexcc.com/blog/whatsapp-business-ecommerce/">
                WhatsApp
              </Link>
            </p>
            <p>
              <Link href="https://beexcc.com/blog/noticias-contact-center/">
                Contact Center
              </Link>
            </p>
            <p>
              <Link href="https://beexcc.com/blog/omnicanalidad-contact-center/">
                Omnicanalidad
              </Link>
            </p>
            <p>
              <Link href="https://beexcc.com/blog/telemarketing/">
                Telemarketing
              </Link>
            </p>
            <p>
              <Link href="https://beexcc.com/blog/ecommerce-news/">Ecommerce</Link>
            </p>
            <p>
              <Link href="https://beexcc.com/blog/chatbots-magazine/">
                Chatbots
              </Link>
            </p>
          </section>
        </section>
        <hr className="hr-fotter" />
        <section className="copyright">
          <div className="copyright-text-politicas">
            <p className="text-copyright">{data.copyright}</p>
            <p className="text-copyright">
              <Link target="_self" href={data.privacyPolicyHref}>
                {data.privacyPolicy}
              </Link>
              |
              <Link target="_self" href={data.cookiesPolicyHref}>
                {data.cookiesPolicy}
              </Link>
            </p>
          </div>
          <div className="social-media">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/beexcc"
              aria-label="red social"
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.2901 10.87C18.2201 11.6 18.1701 12.29 18.0601 12.97C17.9101 13.89 17.8901 13.88 16.9501 13.88C16.5101 13.88 16.0601 13.9 15.6201 13.87C15.2201 13.84 15.0901 13.97 15.1001 14.38C15.1201 16.5 15.1101 18.63 15.1101 20.75C15.1101 21.5 15.1101 22.24 15.1101 23.03C14.9201 23.04 14.7501 23.06 14.5901 23.06C13.5601 23.06 12.5401 23.05 11.5101 23.07C11.1401 23.08 11.0401 22.96 11.0401 22.6C11.0501 19.91 11.0501 17.22 11.0501 14.53C11.0501 13.88 11.0501 13.88 10.4201 13.88C10.0501 13.88 9.67006 13.9 9.30006 13.87C9.20006 13.86 9.01006 13.74 9.01006 13.67C8.99006 12.75 9.00006 11.83 9.00006 10.86C9.52006 10.86 9.99006 10.86 10.4601 10.86C11.0401 10.86 11.0601 10.85 11.0601 10.25C11.0601 9.54002 11.0701 8.83002 11.0601 8.13002C11.0301 6.32002 12.1201 5.14002 13.8001 4.90002C15.1901 4.70002 16.6201 4.79002 18.0301 4.77002C18.1201 4.77002 18.2801 4.95002 18.2801 5.05002C18.3001 5.89002 18.2901 6.74002 18.2901 7.58002C18.2901 7.82002 18.1601 7.88002 17.9501 7.88002C17.3501 7.87002 16.7601 7.87002 16.1601 7.88002C15.5401 7.89002 15.1601 8.23002 15.1201 8.83002C15.0901 9.39002 15.1201 9.95002 15.1101 10.5C15.1101 10.78 15.2601 10.86 15.5101 10.85C16.2901 10.84 17.0601 10.85 17.8401 10.85C17.9601 10.85 18.0901 10.86 18.2901 10.87Z"
                  fill="#84868E"
                />
              </svg>
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/beexcc/"
              aria-label="red social"
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5899 23.07C14.1499 23.07 12.8299 23.07 11.4299 23.07C11.4299 18.83 11.4299 14.59 11.4299 10.29C12.7699 10.29 14.1299 10.29 15.5799 10.29C15.5799 10.8 15.5799 11.29 15.5799 12.03C16.5699 10.69 17.6699 9.98003 19.1099 9.92003C21.9299 9.79003 23.9499 11.24 24.1799 14.04C24.4299 17.02 24.2399 20.03 24.2399 23.05C22.9499 23.05 21.6099 23.05 20.1699 23.05C20.1699 22.67 20.1699 22.32 20.1699 21.98C20.1599 19.84 20.1899 17.7 20.1099 15.56C20.0599 14.06 19.1899 13.22 17.9299 13.24C16.6299 13.26 15.5999 14.34 15.5899 15.76C15.5699 18.17 15.5899 20.58 15.5899 23.07Z"
                  fill="#84868E"
                />
                <path
                  d="M4.75 10.28C6.14 10.28 7.48 10.28 8.87 10.28C8.87 14.56 8.87 18.79 8.87 23.06C7.48 23.06 6.14 23.06 4.75 23.06C4.75 18.8 4.75 14.59 4.75 10.28Z"
                  fill="#84868E"
                />
                <path
                  d="M6.80005 8.36008C5.37005 8.36008 4.46005 7.49008 4.47005 6.13008C4.48005 4.86008 5.41005 4.01008 6.83005 4.00008C8.24005 3.98008 9.24005 4.90008 9.22005 6.20008C9.20005 7.52008 8.26005 8.36008 6.80005 8.36008Z"
                  fill="#84868E"
                />
              </svg>
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/beex_cc/"
              aria-label="red social"
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.25 5.36083C3.67834 6.99333 4 8.7275 4 13.9958C4 18.3708 3.23667 22.7567 7.23167 23.7892C8.47917 24.11 19.5325 24.11 20.7783 23.7875C22.4417 23.3583 23.795 22.0092 23.98 19.6567C24.0058 19.3283 24.0058 8.66916 23.9792 8.33416C23.7825 5.82833 22.24 4.38416 20.2075 4.09166C19.7417 4.02416 19.6483 4.00416 17.2583 4C8.78084 4.00416 6.9225 3.62666 5.25 5.36083Z"
                  fill="#84868E"
                />
                <path
                  d="M13.9983 6.61589C10.9725 6.61589 8.09917 6.34672 7.00167 9.16339C6.54834 10.3267 6.61417 11.8376 6.61417 14.0009C6.61417 15.8992 6.55334 17.6834 7.00167 18.8376C8.09667 21.6559 10.9933 21.3859 13.9967 21.3859C16.8942 21.3859 19.8817 21.6876 20.9925 18.8376C21.4467 17.6626 21.38 16.1742 21.38 14.0009C21.38 11.1159 21.5392 9.25339 20.14 7.85506C18.7233 6.43839 16.8075 6.61589 13.995 6.61589H13.9983ZM13.3367 7.94672C19.6483 7.93672 20.4517 7.23506 20.0083 16.9826C19.8508 20.4301 17.2258 20.0517 13.9992 20.0517C8.11584 20.0517 7.94667 19.8834 7.94667 13.9976C7.94667 8.04339 8.41334 7.95006 13.3367 7.94506V7.94672ZM17.94 9.17256C17.4508 9.17256 17.0542 9.56922 17.0542 10.0584C17.0542 10.5476 17.4508 10.9442 17.94 10.9442C18.4292 10.9442 18.8258 10.5476 18.8258 10.0584C18.8258 9.56922 18.4292 9.17256 17.94 9.17256ZM13.9983 10.2084C11.9042 10.2084 10.2067 11.9067 10.2067 14.0009C10.2067 16.0951 11.9042 17.7926 13.9983 17.7926C16.0925 17.7926 17.7892 16.0951 17.7892 14.0009C17.7892 11.9067 16.0925 10.2084 13.9983 10.2084ZM13.9983 11.5392C17.2525 11.5392 17.2567 16.4626 13.9983 16.4626C10.745 16.4626 10.74 11.5392 13.9983 11.5392Z"
                  fill="white"
                />
              </svg>
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/@beexcc/"
              aria-label="red social"
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.4231 7.27855C27.1003 6.07906 26.1546 5.13357 24.9553 4.81057C22.7642 4.21094 13.9997 4.21094 13.9997 4.21094C13.9997 4.21094 5.23545 4.21094 3.04433 4.78772C1.86813 5.1105 0.899349 6.07928 0.576566 7.27855C0 9.46946 0 14.0132 0 14.0132C0 14.0132 0 18.5798 0.576566 20.7479C0.899563 21.9471 1.84505 22.8928 3.04454 23.2158C5.25852 23.8155 13.9999 23.8155 13.9999 23.8155C13.9999 23.8155 22.7642 23.8155 24.9553 23.2387C26.1548 22.9159 27.1003 21.9702 27.4233 20.7709C27.9999 18.5798 27.9999 14.0363 27.9999 14.0363C27.9999 14.0363 28.023 9.46946 27.4231 7.27855Z"
                  fill="#84868E"
                />
                <path
                  d="M11.2092 18.2109L18.4974 14.0132L11.2092 9.81555V18.2109Z"
                  fill="white"
                />
              </svg>
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://open.spotify.com/show/67SWlI5hIQxvf4YU1eYDRg"
              aria-label="red social"
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 24C19.52 24 24 19.52 24 14C24 8.48 19.52 4 14 4C8.48 4 4 8.48 4 14C4 19.52 8.48 24 14 24ZM18.06 18.7133C17.3875 18.7133 15.2633 16.3575 9.29333 17.5808C9.13583 17.6217 8.93 17.6858 8.81333 17.6858C8.05083 17.6858 7.905 16.545 8.725 16.3708C12.0275 15.6417 15.4025 15.7058 18.2808 17.4275C18.9675 17.8658 18.6758 18.7133 18.06 18.7133ZM19.1458 16.0692C19.0517 16.0442 19.0792 16.1267 18.6483 15.9C16.1275 14.4083 12.3708 13.8067 9.0275 14.7142C8.83417 14.7667 8.72917 14.8192 8.5475 14.8192C7.655 14.8192 7.41833 13.4767 8.39083 13.2025C12.3208 12.0983 16.5367 12.7425 19.4717 14.4883C19.7983 14.6817 19.9275 14.9325 19.9275 15.2825C19.9233 15.7175 19.585 16.0692 19.1458 16.0692ZM7.79 9.83167C11.5592 8.72833 17.2633 9.07667 20.81 11.1467C21.7192 11.6708 21.3617 12.9967 20.395 12.9967L20.3942 12.9958C20.1842 12.9958 20.055 12.9433 19.8733 12.8383C17.0042 11.125 11.87 10.7142 8.54833 11.6417C8.4025 11.6817 8.22083 11.7458 8.0275 11.7458C7.495 11.7458 7.08833 11.33 7.08833 10.7942C7.08833 10.2467 7.4275 9.93667 7.79 9.83167Z"
                  fill="#84868E"
                />
              </svg>
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.tiktok.com/@beex_cc"
              aria-label="red social"
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23 9.06395C22.9831 9.06395 21.2696 9.02907 19.8097 7.92442C18.5358 6.96512 18.0962 4.87791 18.0285 4.10465V4H14.9284V17.7965C14.9284 19.4535 13.6207 20.8023 12.0143 20.8023C10.4078 20.8023 9.10013 19.4535 9.10013 17.7965C9.10013 16.1395 10.4078 14.7907 12.0143 14.7907H12.995V11.593H12.0143C8.69993 11.593 6 14.3779 6 17.7965C6 21.2151 8.69993 24 12.0143 24C15.3286 24 18.0285 21.2151 18.0285 17.7965V10.5407C20.2775 12.2093 22.7125 12.2616 22.9944 12.2616V9.06395H23Z"
                  fill="#84868E"
                />
              </svg>
            </Link>
          </div>
        </section>
      </section>
    </section>
  )
}

export default Footer
